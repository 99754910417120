import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Title from '../../components/common/Title';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import SuccessAlert from '../../components/alerts/SuccessAlert';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import Loading from '../../components/common/ui/Loading';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem 2rem 4rem 2rem;
  margin: auto;
  max-width: 80rem;
`;

const FormContainer = styled.form`
  padding: 2rem 0 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const TextContainer = styled.div`
  margin: auto;
  background-color: white;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    padding-left: 1rem;
  }
`;

const ParagraphContainer = styled.div`
  display: flex;
  margin-bottom: 0.8rem;
`;

const TextHeader = styled.h2`
  margin-bottom: 1rem;
  font-size: clamp(1.2rem, 6vw, 2rem);
`;

const TextParagraph = styled.p`
  font-size: clamp(1rem, 6vw, 1.2rem);
`;

const StyledPaperAirplaneIcon = styled(PaperAirplaneIcon)`
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity));
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
  margin-right: 1rem;
`;

const Button = styled.button`
  --tw-bg-opacity: 1;
  background-color: rgb(224 36 36 / var(--tw-bg-opacity));
  line-height: 1.75rem;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 0.25rem;
  color: #fff;
  font-size: clamp(1rem, 6vw, 1.2rem);

  &:hover {
    background-color: rgb(200 30 30 / var(--tw-bg-opacity));
  }

  &:disabled {
    background-color: gray;
  }
`;

const DEFAULT_VALIDATION_STATE = {
  invalidFirstname: false,
  invalidLastname: false,
  invalidBirthDate: false,
  invalidPhoneNumber: false,
  invalidEmail: false,
  invalidAddress: false,
  invalidPostalCode: false,
  invalidPostalOffice: false,
  missingSubscription: false,
  missingKey: false,
  missingCoaching: false,
};

type MemberType = {
  firstname: string;
  lastname: string;
  birthDate: string;
  phoneNumber: string;
  email: string;
  address: string;
  postalCode: string;
  postalOffice: string;
  subscription: string;
  key: string;
  coaching: string;
};

const validPhoneNumberRegex = /^(?:\d{12}|\d{8}|\+\d{10})$/;
const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const validDateStringRegex =
  /^(?:\d{4})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2][0-9]|3[0-1])$/;

const Member = () => {
  const [member, setMember] = useState<MemberType>({
    firstname: '',
    lastname: '',
    birthDate: '',
    phoneNumber: '',
    email: '',
    address: '',
    postalCode: '',
    postalOffice: '',
    subscription: '',
    key: '',
    coaching: '',
  });

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [validationState, setValidationState] = useState<
    typeof DEFAULT_VALIDATION_STATE
  >(DEFAULT_VALIDATION_STATE);

  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const validate = useCallback(
    (submitted: boolean) => {
      let newState: typeof DEFAULT_VALIDATION_STATE = {
        invalidFirstname: false,
        invalidLastname: false,
        invalidBirthDate: false,
        invalidPhoneNumber: false,
        invalidEmail: false,
        invalidAddress: false,
        invalidPostalCode: false,
        invalidPostalOffice: false,
        missingSubscription: false,
        missingKey: false,
        missingCoaching: false,
      };

      if (submitted) {
        newState = {
          invalidFirstname: !member.firstname || member.firstname === '',
          invalidLastname: !member.lastname || member.lastname === '',
          invalidBirthDate: !validDateStringRegex.test(member.birthDate),
          invalidPhoneNumber: !validPhoneNumberRegex.test(member.phoneNumber),
          invalidEmail: !validEmailRegex.test(member.email),
          invalidAddress: !member.address || member.address === '',
          invalidPostalCode:
            !member.postalCode ||
            member.postalCode.length !== 4 ||
            Number.isNaN(Number.parseInt(member.postalCode)),
          invalidPostalOffice:
            !member.postalOffice || member.postalOffice === '',
          missingSubscription:
            !member.subscription || member.subscription === '',
          missingKey: !member.key || member.key === '',
          missingCoaching: !member.coaching || member.coaching === '',
        };
        setValidationState(newState);
      }

      return (
        !newState.invalidFirstname &&
        !newState.invalidLastname &&
        !newState.invalidBirthDate &&
        !newState.invalidPhoneNumber &&
        !newState.invalidEmail &&
        !newState.invalidAddress &&
        !newState.invalidPostalCode &&
        !newState.invalidPostalOffice &&
        !newState.missingSubscription &&
        !newState.missingKey
      );
    },
    [member],
  );

  function formatDate(date: string) {
    const dateParts = date.split('-');

    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    return `${day}.${month}.${year}`;
  }

  const handleFirstnameChange = (e: any) => {
    return setMember({ ...member, firstname: e.target.value });
  };

  const handleLastnameChange = (e: any) => {
    return setMember({ ...member, lastname: e.target.value });
  };

  const handleBirthDateChange = (e: any) => {
    return setMember({ ...member, birthDate: e.target.value });
  };

  const handlePhoneNumberChange = (e: any) => {
    return setMember({ ...member, phoneNumber: e.target.value });
  };

  const handleEmailChange = (e: any) => {
    return setMember({ ...member, email: e.target.value });
  };

  const handleAddressChange = (e: any) => {
    return setMember({ ...member, address: e.target.value });
  };

  const handlePostalCodeChange = (e: any) => {
    return setMember({ ...member, postalCode: e.target.value });
  };

  const handlePostalOfficeChange = (e: any) => {
    return setMember({ ...member, postalOffice: e.target.value });
  };

  const handleSubscriptionChange = (e: any) => {
    return setMember({ ...member, subscription: e.target.value });
  };

  const handleKeyChange = (e: any) => {
    return setMember({ ...member, key: e.target.value });
  };

  const handleCoachingChange = (e: any) => {
    return setMember({ ...member, coaching: e.target.value });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const token = await reCaptchaRef.current?.executeAsync().then(value => {
      return value;
    });

    setHasSubmitted(true);
    if (validate(true)) {
      setIsLoading(true);

      const response = await fetch(`/api/CreateMember`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname: member.firstname,
          lastname: member.lastname,
          birthDate: formatDate(member.birthDate),
          phoneNumber: member.phoneNumber,
          email: member.email,
          address: member.address,
          postalCode: member.postalCode,
          postalOffice: member.postalOffice,
          subscription: member.subscription,
          key: member.key,
          coaching: member.coaching,
          token: token,
        }),
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        setError(true);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (hasSubmitted) {
      validate(hasSubmitted);
    }
  }, [hasSubmitted, validate]);

  return (
    <Container>
      <Title text='Søknad om medlemskap'></Title>
      {error && !success && (
        <ErrorAlert
          title='Beklager en feil har oppstått!'
          text='Vennligst prøv igjen senere.'
        />
      )}
      <TextParagraph className='whitespace-pre-wrap'>{'Helsehuset Vea er et lavterskel treningstilbud som betyr at treningssenteret er forbeholdt pasienter og voksne over 40 år. Grunnen til disse kriteriene er at hos oss er det fokus på trening skal bidra til å bedre og ivareta helsen, og når man er «voksen» så blir dette enda viktigere.'}
      {'\n\n'}
      {'Ved å fylle ut skjema så vil vi ta en vurdering og ta kontakt med deg.'}</TextParagraph>
      <ContentContainer>
        {isLoading && <Loading />}
        {!isLoading && !success && (
          <FormContainer>
            <div className='flex flex-wrap -mx-3 mb-3'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Fornavn
                </label>
                <input
                  className={
                    'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ' +
                    (validationState.invalidFirstname ? 'border-red-500' : '')
                  }
                  id='firstname'
                  type='text'
                  value={member.firstname}
                  onChange={handleFirstnameChange}
                  placeholder='Fornavn'
                ></input>
                {validationState.invalidFirstname && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst fyll inn fornavn
                  </p>
                )}
              </div>
              <div className='w-full md:w-1/2 px-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Etternavn
                </label>
                <input
                  className={
                    'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ' +
                    (validationState.invalidLastname ? 'border-red-500' : '')
                  }
                  id='lastname'
                  type='text'
                  value={member.lastname}
                  onChange={handleLastnameChange}
                  placeholder='Etternavn'
                ></input>
                {validationState.invalidLastname && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst fyll inn etternavn
                  </p>
                )}
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-3'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0 relative'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Fødselsdato
                </label>
                <input
                  className={
                    'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ' +
                    (validationState.invalidBirthDate ? 'border-red-500' : '')
                  }
                  id='birthDate'
                  type='date'
                  value={member.birthDate}
                  onChange={handleBirthDateChange}
                  placeholder='Fødselsdato'
                ></input>
                {validationState.invalidBirthDate && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst fyll inn gyldig fødselsdato
                  </p>
                )}
              </div>
              <div className='w-full md:w-1/2 px-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Telefonnummer
                </label>
                <input
                  className={
                    'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ' +
                    (validationState.invalidPhoneNumber ? 'border-red-500' : '')
                  }
                  id='phoneNumber'
                  type='text'
                  value={member.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder='Telefonnummer'
                ></input>
                {validationState.invalidPhoneNumber && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst fyll inn gyldig telefonnummer
                  </p>
                )}
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-3'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  E-postadresse
                </label>
                <input
                  className={
                    'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ' +
                    (validationState.invalidEmail ? 'border-red-500' : '')
                  }
                  id='email'
                  type='text'
                  value={member.email}
                  onChange={handleEmailChange}
                  placeholder='E-postadresse'
                ></input>
                {validationState.invalidEmail && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst fyll inn gyldig e-postadresse
                  </p>
                )}
              </div>
              <div className='w-full md:w-1/2 px-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Adresse
                </label>
                <input
                  className={
                    'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ' +
                    (validationState.invalidAddress ? 'border-red-500' : '')
                  }
                  id='address'
                  type='text'
                  value={member.address}
                  onChange={handleAddressChange}
                  placeholder='Adresse'
                ></input>
                {validationState.invalidAddress && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst fyll inn gyldig adresse
                  </p>
                )}
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-3'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Postnummer
                </label>
                <input
                  className={
                    'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ' +
                    (validationState.invalidPostalCode ? 'border-red-500' : '')
                  }
                  id='postalCode'
                  type='text'
                  value={member.postalCode}
                  onChange={handlePostalCodeChange}
                  placeholder='Postkode'
                ></input>
                {validationState.invalidPostalCode && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst fyll inn gyldig postkode
                  </p>
                )}
              </div>
              <div className='w-full md:w-1/2 px-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Poststed
                </label>
                <input
                  className={
                    'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white ' +
                    (validationState.invalidPostalOffice
                      ? 'border-red-500'
                      : '')
                  }
                  id='postalOffice'
                  type='text'
                  value={member.postalOffice}
                  onChange={handlePostalOfficeChange}
                  placeholder='Poststed'
                ></input>
                {validationState.invalidPostalOffice && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst fyll inn gyldig poststed
                  </p>
                )}
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-2'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Abonnement
                </label>
                <div className='relative'>
                  <select
                    className={
                      'appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-2 rounded leading-tight focus:outline-none focus:bg-white ' +
                      (validationState.missingSubscription
                        ? 'border-red-500'
                        : '')
                    }
                    id='subscription'
                    onChange={handleSubscriptionChange}
                    value={member.subscription ?? ''}
                  >
                    <option value='' disabled hidden>
                      Velg abonnement
                    </option>
                    <option value='12 mnd bindingstid Efaktura/Avtalegiro (329,- mnd)'>
                      12 mnd bindingstid Efaktura/Avtalegiro (329,- mnd)
                    </option>
                    <option value='3 mnd, 1 faktura (987,-)'>
                      3 mnd, 1 faktura (987,-)
                    </option>
                    <option value='6 mnd, 1 faktura (1974,-)'>
                      6 mnd, 1 faktura (1974,-)
                    </option>
                    <option value='12 mnd, 1 faktura (3948,-)'>
                      12 mnd, 1 faktura (3948,-)
                    </option>
                  </select>
                </div>
                {validationState.missingSubscription && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst velg et abonnement
                  </p>
                )}
              </div>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Nøkkelbrikke
                </label>
                <div className='relative'>
                  <select
                    className={
                      'appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-2 rounded leading-tight focus:outline-none focus:bg-white ' +
                      (validationState.missingKey ? 'border-red-500' : '')
                    }
                    id='key'
                    onChange={handleKeyChange}
                    value={member.key ?? ''}
                  >
                    <option value='' disabled hidden>
                      Ønsker nøkkelbrikke?
                    </option>
                    <option value='Med nøkkelbrikke (100,-)'>
                      Med nøkkelbrikke (120,-)
                    </option>
                    <option value='Uten nøkkelbrikke'>Uten nøkkelbrikke</option>
                  </select>
                </div>
                {validationState.missingKey && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst velg om ønsket nøkkelbrikke
                  </p>
                )}
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-2'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1'>
                  Treningsveiledning og program
                </label>
                <div className='relative'>
                  <select
                    className={
                      'appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-2 rounded leading-tight focus:outline-none focus:bg-white ' +
                      (validationState.missingCoaching ? 'border-red-500' : '')
                    }
                    id='coaching'
                    onChange={handleCoachingChange}
                    value={member.coaching ?? ''}
                  >
                    <option value='' disabled hidden>
                      Ønsker veiledning?
                    </option>
                    <option value='Ja (450,- 30 min)'>Ja (450,- 30 min)</option>
                    <option value='Nei'>Nei</option>
                  </select>
                </div>
                {validationState.missingCoaching && (
                  <p className='text-red-500 text-xs italic'>
                    Vennligst velg et abonnement
                  </p>
                )}
              </div>

              <div className='w-[100%] md:w-1/2 md:my-5 md:px-14'>
                <ReCAPTCHA
                  size='invisible'
                  ref={reCaptchaRef}
                  sitekey={'6LfYy7gpAAAAABOOr08mUsU_nCA2kadFsLHiNJL3'}
                />
                <Button
                  onClick={onSubmit}
                  disabled={
                    validationState.invalidFirstname ||
                    validationState.invalidLastname ||
                    validationState.invalidBirthDate ||
                    validationState.invalidPhoneNumber ||
                    validationState.invalidEmail ||
                    validationState.invalidAddress ||
                    validationState.invalidPostalCode ||
                    validationState.invalidPostalOffice ||
                    validationState.missingSubscription ||
                    validationState.missingKey ||
                    validationState.missingCoaching ||
                    isLoading
                  }
                >
                  Bli medlem
                </Button>
              </div>
            </div>
          </FormContainer>
        )}
        {!isLoading && success && <SuccessAlert />}

        <TextContainer>
          <TextHeader>Avtalevilkår</TextHeader>
          <ParagraphContainer>
            <StyledPaperAirplaneIcon aria-hidden='true' />
            <TextParagraph>
              Fri tilgang til Helsehuset Vea sin treningsavdeling hver dag i
              tidsperioden kl. 06.00 - 22.00.
            </TextParagraph>
          </ParagraphContainer>
          <ParagraphContainer>
            <StyledPaperAirplaneIcon aria-hidden='true' />
            <TextParagraph>All trening foregår på eget ansvar.</TextParagraph>
          </ParagraphContainer>
          <ParagraphContainer>
            <StyledPaperAirplaneIcon aria-hidden='true' />
            <TextParagraph>
              Nøkkelkortet er personlig og det er ikke tillatt å ta med seg
              andre på sitt nøkkelkort.
            </TextParagraph>
          </ParagraphContainer>
          <ParagraphContainer>
            <StyledPaperAirplaneIcon aria-hidden='true' />
            <TextParagraph>
              Barn under 16 år er ikke tillatt på treningsavdelingen til
              Helsehuset Vea.
            </TextParagraph>
          </ParagraphContainer>
          <ParagraphContainer>
            <StyledPaperAirplaneIcon aria-hidden='true' />
            <TextParagraph>
              Det er 1 mnd oppsigelsestid etter at 12 mnd bindingstid er utløpt.
            </TextParagraph>
          </ParagraphContainer>
          <ParagraphContainer>
            <StyledPaperAirplaneIcon aria-hidden='true' />
            <TextParagraph>
              Medlemsavgiften trekkes den 29. hver måned (Avtalegiro/Efaktura).
            </TextParagraph>
          </ParagraphContainer>
        </TextContainer>
      </ContentContainer>
    </Container>
  );
};

export default Member;
