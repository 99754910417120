import styled from 'styled-components';
import kontor from '../../assets/weights.jpg';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import ButtonDanger from '../common/buttons/ButtonDanger';

const Container = styled.div`
  padding: 2.5rem 0 1.5rem 0;
`;

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 1fr;
  background-color: #fff;

  padding: 2rem;
  width: 60%;
  margin: auto;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

const TextContainer = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
  }
`;

const ParagraphContainer = styled.div`
  display: flex;
  margin-bottom: 0.8rem;
`;

const TextHeader = styled.h2`
  margin-bottom: 0.8rem;
  font-size: clamp(1.5rem, 6vw, 3.5rem);
`;

const TextParagraph = styled.p`
  font-size: clamp(1.2rem, 6vw, 1.4rem);
`;

const ImageContainer = styled.div`
  margin-top: 40%;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

export default function Features() {
  const features = [
    {
      title:
        'Individuelt tilpasset og forskningsbasert behandling ut i fra pasientens helseplager/sykdom',
    },
    {
      title: 'Såletilpasning',
    },
    {
      title: 'Trykkbølgebehandling',
    },
    {
      title: 'Medlemskap på Helsehuset Vea Gym til 329,- per måned',
    },
    {
      title: 'Treningsveiledning og individuelt tilpasset treningsprogram',
    },
    {
      title: 'En god kopp kaffe og sosialt lag i Kaffekroken vår',
    },
  ];

  return (
    <Container>
      <FeaturesContainer>
        <TextContainer>
          <TextHeader>Hva vi tilbyr</TextHeader>
          {features.map(feature => (
            <ParagraphContainer key={feature.title}>
              <CheckCircleIcon
                className='min-h-8 min-w-8 h-8 w-8 text-green-500 mr-4'
                aria-hidden='true'
              />
              <TextParagraph>{feature.title}</TextParagraph>
            </ParagraphContainer>
          ))}
          <div className='max-w-[160px] mt-8'>
            <ButtonDanger buttonText='BLI MEDLEM' buttonLink='/bli-medlem' />
          </div>
        </TextContainer>
        <ImageContainer>
          <img src={kontor} alt='Kontor' />
        </ImageContainer>
      </FeaturesContainer>
    </Container>
  );
}
